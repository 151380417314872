const feedbackList = [
    {
        "id": 0,
        "name": "Nthabeleng Hlapisi, PhD Student, South Africa",
        "message": "I learned how disciplines like sociology, psychology, and communication may help me improve my research and its impact.For example, reflecting on the socioeconomic ramifications of my work in medicinal chemistry for cancer treatment has strengthened my commitment to ensuring that my research benefits society, not simply the scientific community.",
    },
    {
        "id": 1,
        "name": "Elizabeth Buckhold, PhD Student, USA",
        "message": "From the moment I joined the Zoom sessions, she made me believe that our work and collaborations could indeed change the world.",
    },
    {
        "id": 2,
        "name": "Rufaro Kawondera, PhD Student, Zimbabwe",
        "message": "I was particularly impressed by the demographics of the group; the participants were from different countries creating a mixed bag of cultures as well as different academic backgrounds hence different competencies. The sessions were highly interactive and it was a pleasure to interact with our US counter parts, I found their confidence and forthcomingness truly remarkable...",
    },
    {
        "id": 3,
        "name": "Rimbidzai Nyawasha, PhD Student, Zimbabwe",
        "message": "The diversity of the group, in terms of expertise and cultural context has enabled me to improve my ability to explain succinctly what I do to others. Through engagement with women from different disciplines and stages of their doctoral journeys, I was able to gain a more holistic understanding of this journey and insights on how to navigate my own. I have gained some practical skills, such as presentation and time management, which have strengthened my confidence and competence as a woman in science.",
    },
    {
        "id": 4,
        "name": "Jefline Kodzwa, PhD Student, Zimbabwe",
        "message": "ALIMAH nurtured a collaborative environment. Sharing research ideas, troubleshooting challenges, and receiving insightful feedback became the norm. Even a brief 5-minute presentation sparked valuable discussions. When I struggled with Genstat data analysis, fellow participants generously offered free assistance.",
    },
    {
        "id": 5,
        "name": "Eunice Dhokura, PhD Student, Zimbabwe",
        "message": "We shared our experiences, challenges, and successes, and learned from each other's perspectives. I was particularly transformed by Dr. Nana Arthur-Mensah's presentation on intercultural competencies, which emphasized the importance of understanding cultural differences and developing skills to bridge cultural gaps.",
    },
    {
        "id": 6,
        "name": "Anonymous Participant",
        "message": "The guest speakers were wonderful. I really appreciated the topics and the resources",
    },
    {
        "id": 7,
        "name": "Anonymous Participant",
        "message": "I loved the part of sharing our experiences on the things or places we love and motivate us.",
    },
    {
        "id": 8,
        "name": "Nthabeleng Hlapisi, PhD Student, South Africa",
        "message": "I learned how disciplines like sociology, psychology, and communication may help me improve my research and its impact.For example, reflecting on the socioeconomic ramifications of my work in medicinal chemistry for cancer treatment has strengthened my commitment to ensuring that my research benefits society, not simply the scientific community.",
    },
    {
        "id": 9,
        "name": "Elizabeth Buckhold, PhD Student, USA",
        "message": "From the moment I joined the Zoom sessions, she made me believe that our work and collaborations could indeed change the world.",
    },
    {
        "id": 10,
        "name": "Rumbidzai Nyawasha, PhD Student, Zimbabwe",
        "message": "The diversity of the group, in terms of expertise and cultural context has enabled me to improve my ability to explain succinctly what I do to others. Through engagement with women from different disciplines and stages of their doctoral journeys, I was able to gain a more holistic understanding of this journey and insights on how to navigate my own. I have gained some practical skills, such as presentation and time management, which have strengthened my confidence and competence as a woman in science.",
    },
    {
        "id": 11,
        "name": "Monique D. Burey-Ballard, PhD Student, USA",
        "message": "My personal connection with Alimah International was one which amplified my doctoral journey.  Through my participation with the organization, I was able to fellowship with women of varying intersectionality; yet, we were unified by the pursuit of wisdom. For example, in late winter of 2024, I responded to a popular social media outlet’s post which asked for participants within a virtual exchange for female PhD students from Africa and the US.  Held online and paired with activities between sessions, applications to participate were offered through a QR code on programming slated for  March 13-April 24, 2024.",
    },
    {
        "id": 12,
        "name": "Rufaro Kawondera, PhD Student, Zimbabwe",
        "message": "I was particularly impressed by the demographics of the group; the participants were from different countries creating a mixed bag of cultures as well as different academic backgrounds hence different competencies. The sessions were highly interactive and it was a pleasure to interact with our US counterparts. I found their confidence and forthcomingness truly remarkable…",
    },
    {
        "id": 13,
        "name": "Dr Emmerline Nelson-Rogers, PhD Student, USA",
        "message": "The common threads of race, gender, and personal struggles bound us together, despite being at different stages of our academic journeys, living on different continents, and navigating diverse cultural traditions. I joined ALIMAH a few months before defending my PhD dissertation, and in many ways, the group prepared me for both my defense and life after graduation. Intentional conversations with education experts and PhD holders within ALIMAH offered more than just support—it became a space for reflexivity and transformative growth, where our collective stories, challenges, and successes intertwined. We were not just sharing knowledge; we were crafting a narrative that transcended borders, breaking down silos, and allowing our voices to resonate beyond our individual worlds.",
    },
];

export default feedbackList;