import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import resourcesList from '../../Constants/resources';
import HeaderComponent from '../../Components/header.component';
import FooterComponent from '../../Components/footer.component';
import TransitionComponent from '../../Components/transition.component';

export default function ResourcesPage() {
    return (
        <TransitionComponent>
            <HeaderComponent />

            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column64 stretch fill">
                    <div className="column32  stretch">
                        <div className="column16  stretch">
                            <h1 className="primary-title-text white">Resources</h1>
                            <div className='divider white' />
                        </div>

                        {
                            resourcesList.map((resource) =>
                                <div className='column16'>
                                    <h2 className='subtitle-text white'>{resource.resource}</h2>
                                    <div className='col6'>
                                        <Link to={`/resources/${resource.id}`} className="box fill">
                                            <div className="column16 ">
                                                <p className="subtitle-text overflow"><strong>{resource.title}</strong></p>
                                                <p className="subtitle-text overflow">
                                                    <strong>{resource.author}</strong>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='video-container'>
                        <iframe src="https://www.youtube.com/embed/67cdTauSNbI?si=YRppjCBQcUf8maa0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
                    </div>

                </div>
            </motion.section>

            <FooterComponent />
        </TransitionComponent >
    )
}
